import '@/plugins/axios';
import router from '@/router/index';
import { setTimeout } from 'core-js';

const api = '/platform/api/v1/trips/';
const apiT = '/platform/api/v1/trip';
const apiTv = '/platform/api/v1/travel';
const apiW = '/platform/api/v1/who-can-join-options/';
const apiK = '/platform/api/v1/know-go-options/';
const apiN = '/platform/api/v1/not-todo-options/';
const apiF = '/platform/api/v1/faq-options/';
const apiS = '/platform/api/v1/ssq-options/';

export default {
  state: {
    tripsList: {},
    tripsShort: [],
    tripDes: [],
    tripOrganiz: [],
    tripTypes: [],
    trips: {
      options: [],
      media: [],
      saudi_surprise_questions: [],
      traveler_types: {},
      category: {},
      organizer: {},
      departure: {},
      departure_country: {},
      departure_city: {},
      destination_country: {},
      destination_city: {},
      destinations: [],
      filters: [],
    },
    tripCategory: [],
    tripsSchedule: [],
    tripsPlan: [],
    tripTvActiv: [],
    tripTvTypes: [],
    tripTvComponent: [],
    tripWho: [],
    tripKnow: [],
    tripTodo: [],
    tripFaq: [],
    tripsBooking: [],
    tripsSSQ: [],
    tripsSSQItem: { answers: [] },
  },
  actions: {
    getTripsList(context, { search, page, type, country, city, active, tripGroup }) {
      if (!!tripGroup || !!search || page > 1 || type != 'all' || country != 'all' || city != 'all' || active != 'all') {
        if (
          `${router.app._route.path}${type != 'all' ? `?type=${type}&` : '?'}${country != 'all' ? `dst_cnt=${country}&` : ''}${
            city != 'all' ? `dst_cit=${city}&` : ''
          }${active != 'all' ? `act=${active}&` : ''}${!!tripGroup ? `tgr=${tripGroup}&` : ''}${!!search ? `src=${search}&` : ''}page=${
            page || 1
          }` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${type != 'all' ? `?type=${type}&` : '?'}${country != 'all' ? `dst_cnt=${country}&` : ''}${
              city != 'all' ? `dst_cit=${city}&` : ''
            }${active != 'all' ? `act=${active}&` : ''}${!!tripGroup ? `tgr=${tripGroup}&` : ''}${!!search ? `src=${search}&` : ''}page=${
              page || 1
            }`
          );
        }
        return axios
          .get(
            `${api}${type != 'all' ? `?type=${type}&` : '?'}${country != 'all' ? `dst_cnt=${country}&` : ''}${
              city != 'all' ? `dst_cit=${city}&` : ''
            }${active != 'all' ? `act=${active}&` : ''}${!!tripGroup ? `tgr=${tripGroup}&` : ''}${!!search ? `src=${search}&` : ''}page=${
              page - 1 || 0
            }`
          )
          .then((res) => {
            context.commit('GET_TRIPSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_TRIPSLIST', res.data);
        });
      }
    },
    setTripsList: (context) => {
      context.commit('GET_TRIPSLIST', {});
      context.commit('GET_TRIPDES', []);
      context.commit('GET_TRIPTYPES', []);
    },
    getTripsShort(context) {
      return axios.get(`${api}short/`).then((res) => {
        context.commit('GET_TRIPSSHORT', res.data);
      });
    },
    setTripsShort(context) {
      context.commit('GET_TRIPSSHORT', []);
    },
    createTripsItem(context, payload) {
      return axios.post(`${api}`, payload).then((res) => {
        router.push(`/trips/${res.data.id}/general`);
      });
    },
    supportFunction(context, payload) {
      const data = payload;
      if (data.components.some((i) => i.id != 1) || !data.components.length) {
        setTimeout(() => {
          data.components.push({ included: false, component: {} });
        }, 300);
      }
      for (let i = 0; i < data.options.length; i++) {
        data.options[i].priceN = data.options[i].price / 100 || '';
      }
      for (let i = 0; i < data.media.length; i++) {
        setTimeout(() => {
          data.media[i].status == 'processing' ? context.dispatch('getTripsItem') : '';
        }, 1000);
      }
      context.commit('GET_TRIPS', data);
    },
    getTripsItem(context) {
      return axios.get(`${api}${router.app._route.params.tripId}`).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateTripsItem(context, payload) {
      return axios.put(`${api}${router.app._route.params.tripId}`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeTripsItem(context) {
      return axios.delete(`${api}${router.app._route.params.tripId}`).then(() => {
        router.push('/trips');
      });
    },
    featuredTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/featured`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    specialTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/special-offer`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    hiddenTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/hidden`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    templateTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/template`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    loyaltyTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/loyalty-programs`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    mediaTripsItem(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.tripId}/media/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        context.dispatch('getTripsItem');
      });
    },
    mediaDelTripsItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/media/${payload}`).then(() => {
        context.dispatch('getTripsItem');
      });
    },
    getTripsItemPlan(context) {
      return axios.get(`${api}${router.app._route.params.tripId}/booking-plans/`).then((res) => {
        context.commit('GET_TRIPSPLAN', res.data);
      });
    },
    setTripsItem: (context) => {
      context.commit('GET_TRIPS', {
        options: [],
        media: [],
        saudi_surprise_questions: [],
        traveler_types: {},
        category: {},
        organizer: {},
        departure: {},
        departure_country: {},
        departure_city: {},
        destination_country: {},
        destination_city: {},
        destinations: [],
        filters: [],
      });
      context.commit('GET_TRIPORGANIZ', []);
      context.commit('GET_TRIPSSCHEDULE', []);
      context.commit('GET_TRIPSPLAN', []);
      context.commit('GET_TRIPCATEGORY', []);
      context.commit('GET_TRIPTVACTIV', []);
      context.commit('GET_TRIPTVTYPES', []);
      context.commit('GET_TRIPTVCOMPONENTS', []);
      context.commit('GET_TRIPWHO', []);
      context.commit('GET_TRIPKNOW', []);
      context.commit('GET_TRIPTODO', []);
      context.commit('GET_TRIPFAQ', []);
      context.commit('GET_TRIPBOOKING', []);
      context.commit('GET_TRIPSSQ', []);
    },
    postActivTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/activities/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    postFiltersTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/trips-group-filters/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    postTypeTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/traveler-types/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    createComponentTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/components/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    upadateComponentTripsItem(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.tripId}/components/${id}`, data);
    },
    removeComponentTripsItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/components/${payload}`);
    },
    //                               DESTINATIONS
    addTripDestinations(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/destinations/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateTripDestinations(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.tripId}/destinations/${id}`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeTripDestinations(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/destinations/${payload}`);
    },
    updateTripDestinationsOrder(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.tripId}/destinations/${id}/order`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    //                               TRIP
    getTripDes(context) {
      return axios.get(`${apiT}-destinations/`).then((res) => {
        context.commit('GET_TRIPDES', res.data);
      });
    },
    getTripOrganizers(context) {
      return axios.get(`${apiT}-organizers/`).then((res) => {
        context.commit('GET_TRIPORGANIZ', res.data);
      });
    },
    getTripTypes(context) {
      return axios.get(`${apiT}-types/`).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].percent = res.data[i].dw_reward_percent / 100 || 0;
        }
        context.commit('GET_TRIPTYPES', res.data);
      });
    },
    createTripTypes(context, payload) {
      return axios.post(`${apiT}-types/`, payload);
    },
    updateTripTypes(context, { id, data }) {
      return axios.put(`${apiT}-types/${id}`, data);
    },
    updateTripTypesNumber(context, { id, data }) {
      return axios.post(`${apiT}-types/${id}/number`, data);
    },
    getTripCategory(context) {
      return axios.get(`${apiT}-categories/`).then((res) => {
        context.commit('GET_TRIPCATEGORY', res.data);
      });
    },
    //                               TRAVEL
    getTripTravelActiv(context) {
      return axios.get(`${apiTv}-activities/`).then((res) => {
        context.commit('GET_TRIPTVACTIV', res.data);
      });
    },
    getTripTravelTypes(context) {
      return axios.get(`${apiTv}-types/`).then((res) => {
        context.commit('GET_TRIPTVTYPES', res.data);
      });
    },
    setTripTravelTypes(context) {
      context.commit('GET_TRIPTVTYPES', []);
    },
    createTripTravelTypes(context, payload) {
      return axios({
        method: 'post',
        url: `${apiTv}-types/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getTripTravelTypesItem(context, payload) {
      return axios.get(`${apiTv}-types/${payload}`);
    },
    updateTripTravelTypesItem(context, { id, data }) {
      return axios.put(`${apiTv}-types/${id}`, data);
    },
    updateTripTravelTypesItemOrder(context, { id, data }) {
      return axios.post(`${apiTv}-types/${id}/order`, data);
    },
    removeTripTravelTypesItem(context, payload) {
      return axios.delete(`${apiTv}-types/${payload}`);
    },
    updateTripTravelTypesItemPicture(context, { id, data }) {
      return axios({
        method: 'post',
        url: `${apiTv}-types/${id}/picture`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    removeTripTravelTypesItemPicture(context, payload) {
      return axios.delete(`${apiTv}-types/${payload}/picture`);
    },
    getTripTravelConponents(context) {
      return axios.get(`${apiTv}-components/`).then((res) => {
        context.commit('GET_TRIPTVCOMPONENTS', res.data);
      });
    },
    createTripTravelConponents(context, payload) {
      return axios.post(`${apiTv}-components/`, payload).then((res) => {
        // context.commit('GET_TRIPTVCOMPONENTS', res.data);
      });
    },
    updateTripTravelConponents(context, { id, data }) {
      return axios.put(`${apiTv}-components/${id}`, data).then((res) => {
        // context.commit('GET_TRIPTVCOMPONENTS', res.data);
      });
    },
    //                               WHO
    getTripTravelWho(context) {
      return axios.get(`${apiW}`).then((res) => {
        context.commit('GET_TRIPWHO', res.data);
      });
    },
    createTripTravelWho(context, payload) {
      return axios.post(`${apiW}`, payload);
    },
    updateTripTravelWho(context, { id, data }) {
      return axios.put(`${apiW}${id}`, data).then(() => {
        context.dispatch('getTripTravelWho');
      });
    },
    addWhoTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/who-can-join/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeWhoTripsItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/who-can-join/${payload}`).then(() => {
        context.dispatch('getTripsItem');
      });
    },
    //                               KNOW
    getTripTravelKnow(context) {
      return axios.get(`${apiK}`).then((res) => {
        context.commit('GET_TRIPKNOW', res.data);
      });
    },
    createTripTravelKnow(context, payload) {
      return axios.post(`${apiK}`, payload);
    },
    updateTripTravelKnow(context, { id, data }) {
      return axios.put(`${apiK}${id}`, data).then(() => {
        ccontext.dispatch('getTripTravelKnow');
      });
    },
    addKnowTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/know-before-you-go/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeKnowTripsItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/know-before-you-go/${payload}`).then(() => {
        context.dispatch('getTripsItem');
      });
    },
    //                               NOT-TODO
    getTripTravelTodo(context) {
      return axios.get(`${apiN}`).then((res) => {
        context.commit('GET_TRIPTODO', res.data);
      });
    },
    createTripTravelTodo(context, payload) {
      return axios.post(`${apiN}`, payload);
    },
    updateTripTravelTodo(context, { id, data }) {
      return axios.put(`${apiN}${id}`, data).then(() => {
        context.dispatch('getTripTravelTodo');
      });
    },
    addTodoTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/not-to-do/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeTodoTripsItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/not-to-do/${payload}`).then(() => {
        context.dispatch('getTripsItem');
      });
    },
    //                               FAQ
    getTripTravelFaq(context) {
      return axios.get(`${apiF}`).then((res) => {
        context.commit('GET_TRIPFAQ', res.data);
      });
    },
    createTripTravelFaq(context, payload) {
      return axios.post(`${apiF}`, payload);
    },
    updateTripTravelFaq(context, { id, data }) {
      return axios.put(`${apiF}${id}`, data).then(() => {
        ccontext.dispatch('getTripTravelFaq');
      });
    },
    addFaqTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/faqs/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeFaqTripsItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/faqs/${payload}`).then(() => {
        context.dispatch('getTripsItem');
      });
    },
    //                               SSQ
    getTripSSQ(context) {
      return axios.get(`${apiS}`).then((res) => {
        context.commit('GET_TRIPSSQ', res.data);
      });
    },
    addTripSSQItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/ssqs/`, { ssqs: payload }).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeTripSSQ(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/ssqs/${payload}`);
    },
    orderTripSSQ(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.tripId}/ssqs/${id}/order`, data);
    },
    createTripSSQ(context, payload) {
      return axios({
        method: 'post',
        url: apiS,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getTripSSQItem(context) {
      return axios.get(`${apiS}${router.app._route.params.contentId}`).then((res) => {
        context.commit('GET_TRIPSSQITEM', res.data);
      });
    },
    setTripSSQItem(context) {
      context.commit('GET_TRIPSSQITEM', { answers: [] });
    },
    updateTripSSQItem(context, payload) {
      return axios.put(`${apiS}${router.app._route.params.contentId}`, payload).then((res) => {
        context.commit('GET_TRIPSSQITEM', res.data);
      });
    },
    removeTripSSQItem(context, payload) {
      return axios.delete(`${apiS}${payload}`).then(() => {
        context.dispatch('getTripSSQ');
      });
    },
    createTripSSQItemAnswer(context, payload) {
      return axios({
        method: 'post',
        url: `${apiS}${router.app._route.params.contentId}/answers/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_TRIPSSQITEM', res.data);
      });
    },
    updateTripSSQItemAnswer(context, { id, data }) {
      return axios.put(`${apiS}${router.app._route.params.contentId}/answers/${id}`, data);
    },
    updateTripSSQItemAnswerOrder(context, { id, data }) {
      return axios.post(`${apiS}${router.app._route.params.contentId}/answers/${id}/order`, data);
    },
    updateTripSSQItemAnswerPicture(context, { id, data }) {
      return axios({
        method: 'put',
        url: `${apiS}${router.app._route.params.contentId}/answers/${id}/picture`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_TRIPSSQITEM', res.data);
      });
    },
    //                               SCHEDULE
    getTripsItemSchedule(context) {
      return axios.get(`${api}${router.app._route.params.tripId}/schedule-days/`).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].id) {
            res.data[i].events.push({ time: null });
            res.data[i].requirements.push({});
            setTimeout(() => {
              if (res.data[i].media.length) {
                res.data[i].media[0].status == 'processing' ? context.dispatch('getTripsItemSchedule') : '';
              }
            }, 500);
          }
        }
        context.commit('GET_TRIPSSCHEDULE', res.data);
      });
    },
    createTripsItemSchedule(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.tripId}/schedule-days/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    updateTripsItemScheduleItem(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.tripId}/schedule-days/${id}`, data);
    },
    removeTripsItemScheduleItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/schedule-days/${payload}`);
    },
    updateTripsItemScheduleItemMedia(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.tripId}/schedule-days/${id}/media`, data);
    },
    removeTripsItemScheduleItemMedia(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/schedule-days/${payload}/media`).then(() => {
        context.dispatch('getTripsItemSchedule');
      });
    },
    createTripsItemScheduleItemEvent(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.tripId}/schedule-days/${id}/events/`, data);
    },
    updateTripsItemScheduleItemEvent(context, { id, data, event }) {
      return axios.put(`${api}${router.app._route.params.tripId}/schedule-days/${id}/events/${event}`, data);
    },
    removeTripsItemScheduleItemEvent(context, { id, event }) {
      return axios.delete(`${api}${router.app._route.params.tripId}/schedule-days/${id}/events/${event}`);
    },
    createTripsItemScheduleItemReq(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.tripId}/schedule-days/${id}/requirements/`, data);
    },
    updateTripsItemScheduleItemReq(context, { id, data, req }) {
      return axios.put(`${api}${router.app._route.params.tripId}/schedule-days/${id}/requirements/${req}`, data);
    },
    removeTripsItemScheduleItemReq(context, { id, req }) {
      return axios.delete(`${api}${router.app._route.params.tripId}/schedule-days/${id}/requirements/${req}`);
    },
    //                               OPTIONS
    addOptionTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/options/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateOptionTripsItem(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.tripId}/options/${id}`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeOptionTripsItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/options/${payload}`);
    },
    //                               BOOKING
    getBookingTripsItem(context) {
      return axios.get(`${api}${router.app._route.params.tripId}/booking-plans/`).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].components.push({ id: 1, included: false, component: {} });
          res.data[i].windows.push({ begin_time: null });
          if (res.data[i].id) {
            res.data[i].base_priceR = res.data[i].base_price / 100 || '';
          }
        }
        context.commit('GET_TRIPBOOKING', res.data);
      });
    },
    addBookingTripsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.tripId}/booking-plans/`, payload);
    },
    updateBookingTripsItem(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.tripId}/booking-plans/${id}`, data);
    },
    removeBookingTripsItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripId}/booking-plans/${payload}`);
    },
    setBookingTripsItemAccommo(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.tripId}/booking-plans/${id}/accommodations/`, data);
    },
    removeBookingTripsItemAccommo(context, { id, acc }) {
      return axios.delete(`${api}${router.app._route.params.tripId}/booking-plans/${id}/accommodations/${acc}`);
    },
    addBookingTripsItemComponent(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.tripId}/booking-plans/${id}/components/`, data);
    },
    updateBookingTripsItemComponent(context, { id, data, com }) {
      return axios.put(`${api}${router.app._route.params.tripId}/booking-plans/${id}/components/${com}`, data);
    },
    removeBookingTripsItemComponent(context, { id, com }) {
      return axios.delete(`${api}${router.app._route.params.tripId}/booking-plans/${id}/components/${com}`);
    },
    addBookingTripsItemWindow(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.tripId}/booking-plans/${id}/windows/`, data);
    },
    updateBookingTripsItemWindow(context, { id, data, win }) {
      return axios.put(`${api}${router.app._route.params.tripId}/booking-plans/${id}/windows/${win}`, data);
    },
    removeBookingTripsItemWindow(context, { id, win }) {
      return axios.delete(`${api}${router.app._route.params.tripId}/booking-plans/${id}/windows/${win}`);
    },
  },
  mutations: {
    GET_TRIPSLIST: (state, payload) => {
      state.tripsList = payload;
      return state.tripsList;
    },
    GET_TRIPSSHORT: (state, payload) => {
      state.tripsShort = payload;
      return state.tripsShort;
    },
    GET_TRIPDES: (state, payload) => {
      state.tripDes = payload;
      return state.tripDes;
    },
    GET_TRIPORGANIZ: (state, payload) => {
      state.tripOrganiz = payload;
      return state.tripOrganiz;
    },
    GET_TRIPTYPES: (state, payload) => {
      state.tripTypes = payload;
      return state.tripTypes;
    },
    GET_TRIPS: (state, payload) => {
      state.trips = payload;
      return state.trips;
    },
    GET_TRIPCATEGORY: (state, payload) => {
      state.tripCategory = payload;
      return state.tripCategory;
    },
    GET_TRIPSSCHEDULE: (state, payload) => {
      state.tripsSchedule = payload;
      return state.tripsSchedule;
    },
    GET_TRIPSPLAN: (state, payload) => {
      state.tripsPlan = payload;
      return state.tripsPlan;
    },
    GET_TRIPTVACTIV: (state, payload) => {
      state.tripTvActiv = payload;
      return state.tripTvActiv;
    },
    GET_TRIPTVTYPES: (state, payload) => {
      state.tripTvTypes = payload;
      return state.tripTvTypes;
    },
    GET_TRIPTVCOMPONENTS: (state, payload) => {
      state.tripTvComponent = payload;
      return state.tripTvComponent;
    },
    GET_TRIPWHO: (state, payload) => {
      state.tripWho = payload;
      return state.tripWho;
    },
    GET_TRIPKNOW: (state, payload) => {
      state.tripKnow = payload;
      return state.tripKnow;
    },
    GET_TRIPTODO: (state, payload) => {
      state.tripTodo = payload;
      return state.tripTodo;
    },
    GET_TRIPFAQ: (state, payload) => {
      state.tripFaq = payload;
      return state.tripFaq;
    },
    GET_TRIPBOOKING: (state, payload) => {
      state.tripsBooking = payload;
      return state.tripsBooking;
    },
    GET_TRIPSSQ: (state, payload) => {
      state.tripsSSQ = payload;
      return state.tripsSSQ;
    },
    GET_TRIPSSQITEM: (state, payload) => {
      state.tripsSSQItem = payload;
      return state.tripsSSQItem;
    },
  },
  getters: {
    tripsList(state) {
      return state.tripsList;
    },
    tripsShort(state) {
      return state.tripsShort;
    },
    tripDes(state) {
      return state.tripDes;
    },
    tripOrganiz(state) {
      return state.tripOrganiz;
    },
    tripTypes(state) {
      return state.tripTypes;
    },
    trips(state) {
      return state.trips;
    },
    tripCategory(state) {
      return state.tripCategory;
    },
    tripsSchedule(state) {
      return state.tripsSchedule;
    },
    tripsPlan(state) {
      return state.tripsPlan;
    },
    tripTvActiv(state) {
      return state.tripTvActiv;
    },
    tripTvTypes(state) {
      return state.tripTvTypes;
    },
    tripTvComponent(state) {
      return state.tripTvComponent;
    },
    tripWho(state) {
      return state.tripWho;
    },
    tripKnow(state) {
      return state.tripKnow;
    },
    tripTodo(state) {
      return state.tripTodo;
    },
    tripFaq(state) {
      return state.tripFaq;
    },
    tripsBooking(state) {
      return state.tripsBooking;
    },
    tripsSSQ(state) {
      return state.tripsSSQ;
    },
    tripsSSQItem(state) {
      return state.tripsSSQItem;
    },
  },
};
